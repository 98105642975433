<template>
    <section class="widget new-updated-design">
        <div>
            <div class="grid-margin-md-0 stretch-card">
                <div class="col-lg-6 col-md-12 stretch-card new-stretch-card stretch-card-padding">
                    <b-form-group
                            id="input-group-1"
                            :label="$t('date-from')"
                            label-for="input-1"
                            class="grid-margin new-grp"
                    >
                        <b-form-input
                                id="input-1"
                                v-model="form.date_from"
                                type="date"
                                :placeholder="$t('date-from')"
                                required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                            id="input-group-1"
                            :label="$t('date-to')"
                            label-for="input-1"
                            class="ml-1 grid-margin new-grp"
                    >
                        <b-form-input
                                id="input-1"
                                v-model="form.date_to"
                                type="date"
                                :placeholder="$t('date-to')"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <div class="btns-flex">
                    <div class="ml-2 mt-4 grid-margin new-grp">
                        <button data-v-08053910="" @click="filterByDate" class="btn btn-info text-white ss">{{ $t('filter-btn') }}</button>
                    </div>
                    <div class="ml-2 mt-4 grid-margin new-grp">
                        <button data-v-08053910="" @click="resetForm()" class="btn btn-warning  text-white">
                            {{ $t('reset-btn') }}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7 grid-margin grid-margin-md-0">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('spotters') }}</h4>
                        </div>
                        <div class="card-body new-card-body">
                            <vue-good-table
                                    mode="remote"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange"
                                    :totalRows="totalRecords"
                                    :isLoading.sync="isLoading"
                                    :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                    :rows="rows"
                                    :columns="columns"
                                    @on-search="searchFn"
                                    :search-options="{enabled: true,searchFn: searchFn}"
                                    :row-style-class="checkDeleted">
                            </vue-good-table>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 grid-margin grid-margin-md-0">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('birthdays-this-month') }}</h4>
                        </div>
                        <div class="card-body text-center new-card-body">
                            <vue-good-table
                                    mode="remote"
                                    @on-page-change="onPageChange1"
                                    @on-sort-change="onSortChange1"
                                    @on-column-filter="onColumnFilter1"
                                    @on-per-page-change="onPerPageChange1"
                                    :totalRows="totalRecords1"
                                    :isLoading.sync="isLoading1"
                                    :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                    :rows="rows1"
                                    :columns="columns1"
                                    @on-search="searchFn1"
                                    :search-options="{enabled: true,searchFn: searchFn1}"
                                    :row-style-class="checkDeleted">
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import API from '@/api'
    import moment from 'moment'

    export default {
        name: 'spotter-Dashboard',
        components: {},
        data() {
            return {
                form: {
                    date_from: '',
                    date_to: '',
                },
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'spots_count', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Username',
                        field: 'user_name',
                        filterable: true
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Phone',
                        field: 'mobile_phone',
                        sortable: true,
                    },
                    {
                        label: 'Total Spots',
                        field: 'spots_count',
                        sortable: true,
                    },
                ],
                serverParams1: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'dob', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns1: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Date of Birth',
                        field: 'dob',
                        sortable: true,
                    },
                ],
                isLoading: false,
                isLoading1: false,
                rows: [],
                rows1: [],
                totalRecords: 0,
                totalRecords1: 0,
            };
        },
        methods: {
            resetForm() {
                this.form.date_from = '';
                this.form.date_to = '';
                this.loadItems();
                this.loadItems1();
            },
            loadItems() {
                this.isLoading = true;
                API.spotterSpotCount(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.data.total;
                        this.rows = data.data.data;
                    },
                    err => {
                    }
                )
            },
            loadItems1() {
                this.isLoading1 = true;
                API.spotterBirthdays(this.serverParams1,
                    data => {
                        this.isLoading1 = false;
                        this.totalRecords1 = data.data.total;
                        this.rows1 = data.data.data;
                    },
                    err => {
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            updateParams1(newProps) {
                this.serverParams1 = Object.assign({}, this.serverParams1, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onPageChange1(params) {
                this.updateParams1({page: params.currentPage});
                this.loadItems1();
            },
            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },
            onPerPageChange1(params) {
                this.updateParams1({perPage: params.currentPerPage});
                this.loadItems1();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onSortChange1(params) {
                this.updateParams1({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems1();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            onColumnFilter1(params) {
                this.updateParams1(params);
                this.loadItems1();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            searchFn1(params) {
                console.log(params)
                this.updateParams1({searchTerm: params});
                this.loadItems1();
                this.isLoading1 = false;
            },
            filterByDate() {
                this.updateParams({dates: this.form});
                this.updateParams1({dates: this.form});
                this.loadItems();
                this.loadItems1();
            },
            checkDeleted(row) {
                // console.log(row.deleted_at);
                return row.deleted_at !== null ? 'red-text' : '';
            }
        }, computed: {},
        mounted() {
            this.isLoading = true;
            this.isLoading1 = true;
            this.loadItems();
            this.loadItems1();
        },
        beforeMount() {
        },
    }
</script>

<style lang="scss">
    .red-text .vgt-left-align {
        color: red !important;
    }
    .stretch-card-padding {
        padding-left: 0px;
    }
    .btns-flex {
        display: flex;
        align-items: center;
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    @media (max-width: 550px) {
        .new-stretch-card {
            flex-direction: column;
        }
        .new-grp {
            margin: 10px 0px !important;
        }
        .btns-flex .ml-2:nth-child(2) {
            margin-left: 5px !important;
        }
        .vgt-wrap__footer .footer__navigation>button:first-of-type {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .vgt-wrap__footer .footer__navigation__page-btn {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .vgt-wrap__footer .footer__navigation__page-info {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .vgt-wrap__footer .footer__navigation__page-btn .chevron {
            width: 12px;
        }
    }
</style>
